import { render, staticRenderFns } from "./VolunteersSection.vue?vue&type=template&id=00af98bb&scoped=true"
import script from "./VolunteersSection.vue?vue&type=script&lang=js"
export * from "./VolunteersSection.vue?vue&type=script&lang=js"
import style0 from "./VolunteersSection.vue?vue&type=style&index=0&id=00af98bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00af98bb",
  null
  
)

export default component.exports